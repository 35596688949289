.grid {
    padding: 50px 0;

    &.contacts {

        background-color: #333;
        color: white;

        .grid_inner {

            .grid_content {

                .grid_item {
                    flex-direction: column;
                    align-items: center;
                    row-gap: 10px;
        
                    cursor: pointer;
                    color: #F0B327;

                    transition: all .3s;

                    &:hover {
                        opacity: 0.5;
                    }
                 
                    .grid_item_content {
                        display: flex;
                        flex-direction: column;
                        row-gap: 10px;
                        text-align: center;

        
                        .grid_item_title {
                            padding: 0;
                            color: white;
                        }

                        .grid_item_text {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    .grid_inner {

        .grid_content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            row-gap: 20px;

            .grid_item {
                width: 31%;

                display: flex;
                column-gap: 10px;

                color: black;
                text-decoration: none;
                cursor: auto;
             
                .grid_item_content {

                    .grid_item_title {
                        font-size: 18px;
                        padding: 5px 0;
                    }

                    .grid_item_text {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}


@media(max-width: 768px) {
    .grid {
    
        .grid_inner {
    
            .grid_content {
                
                .grid_item {
                    width: 48%;
                }
            }
        }
    }
}

@media(max-width: 600px) {
    .grid {
    
        .grid_inner {
    
            .grid_content {
                
                .grid_item {
                    width: 100%;
                }
            }
        }
    }
}