
.gallery {
    display: flex;
    flex-wrap: wrap;
    
    img {
        width: 50%;
    }
}

@media(max-width: 420px) {
    .gallery {

        img {
            width: 100%;
        }
    }
}