html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	position: relative;
  	font-family: 'Montserrat', sans-serif;
	background-color: #ffffff;

	.container {
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 0 20px;

		height: 100%;
	}
}


*,
*::before,
*::after {
  box-sizing: border-box;
}

.hover_3 {
	transition: all .3s;
	cursor: pointer;

	&:hover {
		opacity: 0.7;
	}
}


.page_title {
	font-size: 30px;
	font-weight: 800;
}

.page_underline {
	margin: 20px 0;
	width: 30%;
	height: 3px;
	background-color: #F0B327;
}

.page_button {
	width: 200px;
	height: 50px;

	font-size: 20px;
	border-radius: 10px;
	background-color: #F0B327;

	font-weight: bold;

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		
		width: 100%;
		height: 100%;
		color: #333;
		text-decoration: none;
	}
}



@media(max-width: 768px) {
    .page_title {
		font-size: 24px;
	}

	body {
		.container {
			padding: 0 10px;
		}
	}
}