.table {
    padding: 50px 0;

    .table_inner {

        .table_item {

            .table_header {
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 5px;

                .table_img {
                    width: 70px;
                }

                .table_title {
                    font-size: 20px;
                }
            }

            .table_list {
                columns: auto 2;
                text-align: center;

                .table_document {
                    margin: 5px 0 0 25px;
                }
            }
        }
    }
}

@media(max-width: 850px) {
    .table {
    
        .table_inner {
    
            .table_item {
    
                .table_list {
                    columns: 1;
                    
                    .table_document {
                        margin: 5px 0 0 0;
                    }
                }
            }
        }
    }    
}

@media(max-width: 460px) {
    .table {
    
        .table_inner {
    
            .table_item {
    
                .table_list {

                    .table_document {
                        font-size: 14px;
                    }
                }
            }
        }
    }    
}