
.navbar {
    padding: 20px 0;
  
    &_links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 20px;
      padding: 20px 0;

      z-index: 2;

      text-align: center;

      font-size: 18px;
      
      color: white;
  
      background-color: rgba(0, 0, 0, 0.7);
      position: absolute;
      top: 70px;
      left: 0;
      right: 0;
      opacity: 0;
      transition: opacity 0.3s ease, visibility 0.3s ease;
      visibility: hidden;
      backdrop-filter: blur(10px);
  
      &.active {
        opacity: 1;
        visibility: visible;
      }
  
      &_link {
        margin: 10px;
        font-size: 1.2rem;
        font-weight: 600;
        text-decoration: none;
        transition: color 0.2s ease;
      }

      a {
          color: #ffffff;
          text-decoration: none;
          
          &:hover {
            color: #F0B327;
        }
      }
    }
  
    &_burger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 40px;
      height: 30px;
      cursor: pointer;
      padding: 5px;
  
      &-line {
        width: 100%;
        height: 3px;
        background-color: #ffffff;
        border-radius: 2px;
      }
    }
  }
  

@media(max-height: 640px) {
  .navbar {
    
    &_links {
      row-gap: 10px;
      padding: 10px 0;
    }
  }
}