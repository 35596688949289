.cards {

    display: flex;
    flex-direction: column;
    align-items: center;

    .card {

        .card_inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 10px;
    
            text-align: center;
            width: 100%;
    
            margin: 0 auto;
            padding: 25px 0;
    
            .card_title {
                font-size: 24px;
            }
    
            .card_text {
                width: 80%;
                margin: 0 auto;
            }
    
            .card_img {
                width: 80%;
                height: 300px;
                object-fit: cover;
            }
    
            a {
                color: #000;
                text-decoration: none;
            }
        }
    
        &:nth-child(2n) {
            background-color: rgb(230, 230, 230);
        }
    }
}

@media(max-width: 768px) {
    .cards {
    
        .card {
    
            .card_inner {
        
                .card_title {
                }
        
                .card_text {
                    width: 100%;
                    font-size: 14px;
                }
        
                .card_img {
                    width: 100%;
                }
        
                a {
                }
            }
        }
    }
}