header {
    position: fixed;
    width: 100%;
    height: 70px;

    z-index: 1;

    color: white;

    background-color: rgba(0, 0, 0, 1);

    .header_inner {
        height: 100%;

            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header_item_logo {
                display: flex;
                align-items: center;
                column-gap: 10px;

                color: white;
                text-decoration: none;
    
                font-size: 18px;
    
                img {
                    width: 50px;
                }

                .header_logo_title {
                    font-size: 24px;
                }
            }
    }
}