.list {
    padding: 50px 0;

    background-color: #333;
    color: white;

    .list_inner {

        .list_content {
            display: flex;
            flex-direction: column;
            row-gap: 50px;

        }

        .list_item {

            display: flex;
            flex-direction: column;
            color: #333;


            box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
            
            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                border: 2px solid #F0B327;
            }

            .list_text {
                line-height: 20px;
                background-color: #F0B327;
                padding: 20px;
                font-weight: bold;
            }

            .list_item_number {
                position: absolute;

                padding: 20px;
                color: #F0B327;
                font-size: 30px;
                font-weight: bold;

                background-color: #3333336c;
                backdrop-filter: blur(5px);
            }
        }
    }
}

@media(max-width: 768px) {
    .list {

        .list_inner {
    
            .list_item {
    
                .list_text {
                    font-size: 14px;
                    padding: 5px;
                }
            }
        }
    }
}